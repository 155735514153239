<template>
  <!--  GSB Mobile  -->
  <div>
    <b-overlay :show="isTogging">
      <div class="row">
        <div class="col-sm-4 col-lg-3">
          <h5>
            Mymo by GSB
            <AutoBankingStatus :status="!!gsbAccount" />
            <br>
            <small class="text-muted">(รองรับฝากและถอน)</small>
          </h5>
        </div>

        <div class="col-sm-8 col-lg-9 pt-2">
          <b-form-checkbox
              v-if="gsbAccount"
              id="gsbAccountStatus"
              v-model="gsbAccount.isEnabled"
              :disabled="isTogging"
              name="gsbAccountStatus"
              switch
              @click.native.prevent="onToggleStatus"
          >
            เปิดใช้งาน
          </b-form-checkbox>
            <AutoBankingLinkButton
              :status="!!gsbAccount"
              class="mr-1"
              @click="$bvModal.show('gsb-mobile-app-modal')"
            />
            <AutoBankingUploadImageButtonButton
              v-if="!!gsbAccount"
              @click="$bvModal.show('gsb-upload-image-modal')"
            />
          <MobileAppModal :agent-bank-account-id="bankAccountForm.id" :gsb-account="gsbAccount" />
          <UploadImageModal :agent-bank-account-id="bankAccountForm.id" :gsb-account="gsbAccount" />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'AutoGsbForm',
  components: {
    MobileAppModal: () => import('./mobile-app-modal'),
    UploadImageModal: () => import('./upload-image-modal'),
    AutoBankingStatus: () => import('../auto-banking-status'),
    AutoBankingLinkButton: () => import('../auto-banking-link-button'),
    AutoBankingUploadImageButtonButton: () => import('../auto-banking-upload-image-button'),
  },
  props: {
    bankAccountForm: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        gsbAccount: null
      })
    }
  },
  data() {
    return {
      gsbAccount: null,
      isTogging: false,
    }
  },
  created() {
    this.gsbAccount = this.bankAccountForm.gsbAccount
  },
  methods: {
    ...mapActions(['toggleBankingStatus']),
    async toggleStatus(banking) {
      return this.toggleBankingStatus({
        id: this.bankAccountForm.id,
        banking,
      })
    },
    async onToggleStatus() {
      this.isTogging = true
      const isSuccess = await this.toggleStatus('gsb-mobile')
      if (isSuccess) {
        this.gsbAccount.isEnabled = !this.gsbAccount.isEnabled
      }
      this.isTogging = false
    }
  }
})
</script>

<style scoped>

</style>